import React from 'react'

const Statec = ({ secondaryColor='#FCC301', color='var(--secondary)', height=50, ...rest}) => {
  return (
    <div {...rest}>
    <a href="https://statistiques.public.lu" target="_blank" rel="noopener noreferrer">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    	 viewBox="0 0 230 85" height={height}>
    <g>
    	<path fill={color} d="M29.1,0c0.7,0.3,1.5,0.2,2.3,0.3c2.7,0.5,5.2,1.2,7.4,3c2.4,1.9,3.5,4.5,3.6,7.5c0,1.2,0,2.4,0,3.6
    		c0,1.1-0.5,1.6-1.6,1.6c-2,0-4.1,0-6.1,0c-1.1,0-1.5-0.5-1.6-1.6c0-0.8,0-1.6,0-2.3c-0.1-2-1.1-3.3-3-3.9c-2.5-0.8-5.1-0.8-7.7,0
    		c-2.1,0.7-3,2.1-3,4.6c0,0.7,0,1.3,0.1,2c0.4,1.4,1.4,2.2,2.7,2.7c3.2,1.2,6.5,2.3,9.7,3.5c1.6,0.6,3.3,1.1,4.9,1.9
    		c2.7,1.4,4.7,3.3,5.6,6.3c0.3,0.9,0.4,1.8,0.4,2.7c0,2.1,0.1,4.3-0.1,6.4c-0.3,4.2-2.6,7-6.3,8.7c-2.7,1.3-5.6,1.7-8.6,1.8
    		c-3.3,0.1-6.7,0-9.9-1c-2.5-0.7-4.8-1.9-6.5-4c-1.4-1.7-2.1-3.7-2.1-6c0-1.7,0-3.3,0-5c0-1.2,0.5-1.7,1.7-1.7c2,0,4,0,5.9,0
    		c1.2,0,1.6,0.4,1.6,1.6c0,1,0,2.1,0,3.1c0,2.4,1.1,4,3.4,4.7c2.7,0.8,5.5,0.8,8.2,0c2.3-0.7,3.4-2.3,3.4-4.7c0-0.5,0-1.1,0-1.6
    		c0.1-1.6-0.7-2.8-2-3.7c-1.5-1-3.2-1.5-4.8-2.1c-3.4-1.3-6.7-2.6-10.1-3.8c-4-1.5-6.5-5.2-6.5-9.5c0-1.9-0.1-3.7,0.1-5.6
    		c0.6-3.9,2.9-6.5,6.5-8c2-0.8,4.1-1.2,6.2-1.4c0.1,0,0.3,0.1,0.4-0.1C25.2,0,27.2,0,29.1,0z"/>
    	<path fill={color} d="M228.5,39c-0.2,0.4-0.2,0.8-0.3,1.2c-0.9,3.5-3.2,5.7-6.4,7.1c-2.6,1.1-5.3,1.5-8.1,1.6
    		c-3.2,0.1-6.4,0-9.5-0.8c-2.3-0.6-4.4-1.6-6-3.3c-1.9-2-2.8-4.4-2.8-7.1c0-8.8,0-17.5,0-26.3c0-4.6,2.5-8.1,7.1-9.8
    		c2-0.7,4-1.1,6.1-1.3c0.1,0,0.3,0.1,0.4-0.1c1.9,0,3.8,0,5.6,0c0.1,0.2,0.4,0.1,0.6,0.1c2.2,0.2,4.3,0.5,6.3,1.3
    		c3.2,1.2,5.6,3.3,6.7,6.8c0.2,0.5,0.2,1.1,0.4,1.6c0,1.9,0,3.8,0,5.8c-0.3,0.6-0.7,1-1.4,1c-2.1,0-4.2,0-6.3,0
    		c-1,0-1.5-0.5-1.5-1.5c0-0.6,0-1.3,0-1.9c0-2.3-1.1-4-3.4-4.7c-2.5-0.8-5-0.8-7.4,0c-2.3,0.7-3.5,2.3-3.5,4.7c0,7.6,0,15.2,0,22.8
    		c0,2.4,1.2,3.9,3.4,4.6c2.4,0.7,4.9,0.7,7.4,0c2.3-0.7,3.4-2.3,3.4-4.7c0-0.9,0-1.8,0-2.6c0-1.3,0.4-1.8,1.8-1.8c2,0,3.9,0,5.9,0
    		c0.8,0,1.3,0.3,1.6,1C228.5,34.5,228.5,36.7,228.5,39z"/>
    	<path fill={secondaryColor} d="M0.1,74.4c0.3-0.7,0.9-0.9,1.6-0.9c0.2,0,0.4,0,0.6,0c9.7,0,19.3,0,29,0c0.2,0,0.4,0,0.6,0
    		c1.2,0.1,1.7,0.5,1.7,1.8c0,1.4,0,2.9,0,4.3c0,0.8-0.3,1.5-1.1,1.8c-10.4,0-20.9,0-31.3,0c-0.5-0.2-0.9-0.5-1.1-1.1
    		C0.1,78.3,0.1,76.3,0.1,74.4z"/>
    	<path fill={color} d="M159.5,24.4c0-7.2,0-14.3,0-21.5c0-0.1,0-0.2,0-0.4c0-1.6,0.4-2,2-2c7.8,0,15.7,0,23.5,0c0.3,0,0.5,0,0.8,0
    		c0.8,0.1,1.2,0.5,1.2,1.3c0,1.7,0,3.4,0,5.2c0,0.9-0.6,1.4-1.6,1.4c-2.9,0-5.8,0-8.6,0c-2.4,0-4.8,0-7.3,0c-0.4,0-0.5,0.1-0.5,0.5
    		c0,3.5,0,7,0,10.4c0,0.4,0.2,0.5,0.5,0.5c4.8,0,9.7,0,14.5,0c1.4,0,1.9,0.4,1.9,1.9c0,1.3,0,2.5,0,3.8c0,1.5-0.5,1.9-1.9,1.9
    		c-4.8,0-9.6,0-14.4,0c-0.5,0-0.6,0.1-0.6,0.6c0,4,0,8,0,11.9c0,0.5,0.2,0.6,0.6,0.6c5.4,0,10.8,0,16.3,0c1.5,0,1.9,0.4,1.9,1.9
    		c0,1.4,0,2.8,0,4.2c0,1.3-0.5,1.8-1.8,1.8c-8.3,0-16.5,0-24.8,0c-1.2,0-1.6-0.4-1.7-1.5c0-0.2,0-0.4,0-0.7
    		C159.5,38.9,159.5,31.6,159.5,24.4z"/>
    	<path fill={color} d="M100,15.4c-2.1,7.2-4.1,14.3-6.2,21.3c-0.9,3.2-1.9,6.4-2.8,9.7c-0.5,1.6-0.9,2-2.6,2c-1.7,0-3.4,0-5.2,0
    		c-1.4,0-1.8-0.6-1.4-1.9C86.3,31.7,90.7,17,95.1,2.3c0.4-1.3,1-1.8,2.4-1.8c1.9,0,3.8,0,5.7,0c1.5,0,2.1,0.4,2.6,1.9
    		c2.8,9.5,5.6,19,8.4,28.5c1.5,5.1,3,10.3,4.6,15.4c0.1,0.3,0.2,0.6,0.1,0.9c0,0.5-0.3,0.7-0.7,0.9c-0.3,0.1-0.6,0.1-0.9,0.1
    		c-1.9,0-3.9,0-5.8,0c-1.4,0-1.9-0.4-2.3-1.7c-1.1-3.9-2.2-7.7-3.4-11.6c-1.8-6.4-3.7-12.7-5.5-19.1C100.2,15.7,100.1,15.6,100,15.4
    		z"/>
    	<path fill={color} d="M69.9,27.8c0,6.2,0,12.3,0,18.5c0,1.5-0.5,2-2,2c-1.9,0-3.8,0-5.6,0c-1.5,0-2-0.5-2-2c0-4.7,0-9.4,0-14
    		c0-7.8,0-15.5,0-23.3c0-0.5-0.1-0.7-0.7-0.6c-3.1,0-6.2,0-9.3,0c-1.4,0-1.8-0.4-1.8-1.8c0-1.5,0-3,0-4.4c0-1.1,0.4-1.5,1.5-1.6
    		c0.2,0,0.4,0,0.5,0c9.6,0,19.3,0,28.9,0c0.1,0,0.2,0,0.4,0c1.3,0,1.8,0.5,1.8,1.8c0,1.4,0,2.8,0,4.2c0,1.4-0.5,1.8-1.8,1.8
    		c-3.1,0-6.2,0-9.2,0c-0.5,0-0.7,0.1-0.7,0.7C69.9,15.3,69.9,21.6,69.9,27.8z"/>
    	<path fill={color} d="M140.5,27.8c0,6.1,0,12.3,0,18.4c0,0.2,0,0.3,0,0.5c0,1.1-0.5,1.6-1.5,1.6c-2.2,0-4.3,0-6.5,0
    		c-1,0-1.4-0.5-1.5-1.5c0-0.3,0-0.6,0-0.8c0-12.3,0-24.6,0-36.9c0-0.6-0.1-0.7-0.7-0.7c-3.1,0-6.2,0-9.2,0c-1.4,0-1.8-0.4-1.8-1.8
    		c0-1.5,0-3,0-4.5c0-1.1,0.3-1.5,1.4-1.6c0.2,0,0.4,0,0.7,0c9.6,0,19.2,0,28.8,0c0.1,0,0.3,0,0.4,0c1.3,0.1,1.7,0.5,1.7,1.8
    		c0,1.4,0,2.8,0,4.3c0,1.4-0.5,1.8-1.8,1.8c-3.1,0-6.2,0-9.3,0c-0.6,0-0.6,0.2-0.6,0.7C140.5,15.3,140.5,21.6,140.5,27.8z"/>
    </g>
    </svg>
    </a>
    </div>
  )
}

export default Statec
