import React from 'react'

const C2dhUnilu = ({ color='#fff', height=50, ...rest}) => {
  return (
    <div {...rest}>
    <a href="https://www.c2dh.uni.lu" target="_blank" rel="noopener noreferrer">
    <svg height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.68 57.12">
      <rect fill={color} x="95.99" y="0.04" width="0.85" height="57.08"/>
      <path fill={color} d="M20,44a2.08,2.08,0,0,1-2.2-2.23V38.24h.78v3.51A1.34,1.34,0,0,0,20,43.25a1.37,1.37,0,0,0,1.45-1.5V38.24h.76v3.51A2.13,2.13,0,0,1,20,44"/>
      <path fill={color} d="M27.52,43.89l-3-4.09-.1-.16a1.83,1.83,0,0,1,0,.19v4.06h-.77V38.24h.65l2.93,4,.1.16a1.54,1.54,0,0,1,0-.19v-4h.77v5.65h-.62"/><rect fill={color} x="29.71" y="38.24" width="0.77" height="5.64"/>
      <path fill={color} d="M34.12,43.89h-.48l-2-5.65h.75l1.43,4.08a1.06,1.06,0,0,1,.05.19l0-.19,1.44-4.08h.73l-2,5.65"/>
      <polyline fill={color} points="37.28 43.88 37.28 38.24 40.43 38.24 40.43 38.97 38.05 38.97 38.05 40.77 40.38 40.77 40.38 41.5 38.05 41.5 38.05 43.16 40.58 43.16 40.58 43.88 37.28 43.88"/>
      <path fill={color} d="M43.64,39h-.91v1.94h.89a1,1,0,0,0,1.1-1,1,1,0,0,0-1.08-.9M45,43.89l-2.28-2.64v2.64H42V38.24h1.82a1.66,1.66,0,0,1,1.75,1.66,1.64,1.64,0,0,1-1.61,1.62L46,43.89Z"/>
      <path fill={color} d="M48.5,44a2.54,2.54,0,0,1-1.66-.57l.44-.6a1.91,1.91,0,0,0,1.18.42c.57,0,1-.26,1-.79s-.44-.74-.94-1c-.81-.41-1.62-.76-1.62-1.84a1.46,1.46,0,0,1,1.63-1.45,2.61,2.61,0,0,1,1.52.48l-.42.63a1.7,1.7,0,0,0-1.11-.35c-.44,0-.81.22-.81.65,0,.61.54.81,1.24,1.16s1.32.73,1.32,1.68A1.58,1.58,0,0,1,48.5,44"/><rect fill={color} x="51.5" y="38.24" width="0.77" height="5.64"/><polyline fill={color} points="55.47 38.97 55.47 43.88 54.7 43.88 54.7 38.97 53.55 38.97 53.55 38.24 56.67 38.24 56.67 38.97 55.47 38.97"/>
      <path fill={color} d="M59.77,37.79H59l.72-1.13h.74l-.72,1.13M58,43.89V38.24H61.1V39H58.72v1.8h2.33v.73H58.72v1.66h2.53v.73Z"/>
      <path fill={color} d="M67.17,38.94h-.65v4.25H67a2.13,2.13,0,1,0,.15-4.25m0,5H65.75V38.24h1.43a2.77,2.77,0,0,1,3,2.81A2.83,2.83,0,0,1,67.12,43.89Z"/>
      <path fill={color} d="M73.7,44a2.08,2.08,0,0,1-2.2-2.23V38.24h.77v3.51a1.35,1.35,0,0,0,1.43,1.5,1.36,1.36,0,0,0,1.44-1.5V38.24h.77v3.51A2.13,2.13,0,0,1,73.7,44"/><rect fill={color} x="17.19" y="33.66" width="59.3" height="0.81"/><polyline fill={color} points="17.79 53.7 17.79 48.05 18.57 48.05 18.57 52.97 20.71 52.97 20.71 53.7 17.79 53.7"/>
      <path fill={color} d="M24.38,53.79a2.07,2.07,0,0,1-2.21-2.23V48.05H23v3.51a1.34,1.34,0,0,0,1.43,1.5,1.36,1.36,0,0,0,1.44-1.5V48.05h.76v3.51a2.12,2.12,0,0,1-2.2,2.23"/>
      <path fill={color} d="M32,53.7l-1.26-1.93-.24-.39s-.15.25-.26.41L29,53.7h-.89l1.95-3-1.73-2.67h1l1.08,1.67c.09.14.18.3.18.3s.09-.16.19-.31l1.09-1.66h.88L31,50.68l2,3H32"/><polyline fill={color} points="34.45 53.7 34.45 48.05 37.6 48.05 37.6 48.78 35.22 48.78 35.22 50.58 37.55 50.58 37.55 51.31 35.22 51.31 35.22 52.97 37.75 52.97 37.75 53.7 34.45 53.7"/>
      <path fill={color} d="M44.47,53.7V49.55a1.41,1.41,0,0,1,0-.22l-.08.13-1.77,2.39h-.31l-1.79-2.33a1,1,0,0,1-.07-.12s0,.11,0,.21V53.7h-.77V48.05h.66l2.12,2.76,2.07-2.76h.68V53.7h-.77"/>
      <path
      fill={color}
      d="M49.07,51.29h-.91V53H49c.7,0,1-.34,1-.86a.83.83,0,0,0-.94-.82m-.32-2.51h-.59v1.79h.52a.9.9,0,0,0,1-1C49.71,49.08,49.38,48.78,48.75,48.78Zm.33,4.92H47.39V48.05h1.49a1.49,1.49,0,0,1,1.64,1.47,1.46,1.46,0,0,1-.65,1.3,1.44,1.44,0,0,1,1,1.35A1.56,1.56,0,0,1,49.08,53.7Z"/>
      <path fill={color} d="M55,48.72a2,2,0,0,0-2.07,2.14A2.13,2.13,0,0,0,55,53a2.07,2.07,0,0,0,2.08-2.18A2,2,0,0,0,55,48.72m0,5.07a2.85,2.85,0,0,1-2.9-2.93,2.89,2.89,0,1,1,5.78,0A2.88,2.88,0,0,1,55,53.79Z"/>
      <path fill={color} d="M61.63,53.79a2.07,2.07,0,0,1-2.2-2.23V48.05h.78v3.51a1.34,1.34,0,0,0,1.42,1.5,1.36,1.36,0,0,0,1.45-1.5V48.05h.76v3.51a2.13,2.13,0,0,1-2.21,2.23"/>
      <path fill={color} d="M67.28,48.78h-.91v1.94h.9a1,1,0,1,0,0-1.94m1.37,4.92-2.28-2.64V53.7H65.6V48.05h1.82a1.66,1.66,0,0,1,1.75,1.67,1.64,1.64,0,0,1-1.61,1.61l2.08,2.37Z"/>
      <path fill={color} d="M73.42,53.79a2.9,2.9,0,0,1-2.86-2.92A2.83,2.83,0,0,1,73.43,48a3,3,0,0,1,2.08.77L75,49.3a2.37,2.37,0,0,0-1.62-.58,2,2,0,0,0-2,2.16A2.14,2.14,0,0,0,73.45,53a1.65,1.65,0,0,0,1.66-1.13h-1.7v-.73H76a2.6,2.6,0,0,1-2.59,2.62"/>
      <path fill={color} d="M61,4.79a2.15,2.15,0,0,1-1.92-1.2h-.25V27.75a2.17,2.17,0,0,0,4.33,0V3.59h-.25A2.13,2.13,0,0,1,61,4.79"/>
      <path fill={color} d="M45.47,10.12a2.11,2.11,0,0,1-1.91-1.19h-.25V27.75a2.16,2.16,0,0,0,4.31,0V8.93h-.24a2.11,2.11,0,0,1-1.91,1.19"/>
      <path fill={color} d="M43.31,5.78A2.16,2.16,0,1,1,45.47,8a2.16,2.16,0,0,1-2.16-2.17"/>
      <path fill={color} d="M19.93,29.9h0a2.16,2.16,0,0,1-2.16-2.15V11.08a2.16,2.16,0,0,1,4.32,0V29.9H19.93"/>
      <path fill={color} d="M23.25,29.9h0V11.08a2.16,2.16,0,0,1,4.32,0V29.9h0c-1,0-1.26-1.18-1.26-2.33h0a2.31,2.31,0,0,1-2.15,2.33h-.9"/>
      <path fill={color} d="M38.19,8.93h0a2.16,2.16,0,0,1,2.16,2.16V27.75a2.16,2.16,0,0,1-4.32,0V8.93h2.16"/>
      <path fill={color} d="M34.87,8.93h0V27.75a2.16,2.16,0,0,1-4.32,0V8.93h0c1,0,1.26,1.18,1.26,2.34h0A2.33,2.33,0,0,1,34,8.93h.91"/>
      <path fill={color} d="M68.29,29.9h0a2.15,2.15,0,0,1-2.15-2.15V11.08a2.16,2.16,0,0,1,4.31,0V29.9H68.29"/>
      <path fill={color} d="M71.62,29.9h0V11.08a2.16,2.16,0,0,1,4.31,0V29.9h0c-1,0-1.25-1.18-1.25-2.34h0a2.33,2.33,0,0,1-2.16,2.34h-.9"/>
      <path fill={color} d="M51.08,27.74a2.17,2.17,0,1,1,2.17,2.16,2.17,2.17,0,0,1-2.17-2.16"/>
      <path fill={color} d="M115.87,47.23V43.76h.47v3h1.3v.45Z"/>
      <path
      fill={color}
      d="M120.67,46.48a1.28,1.28,0,0,1-.28.43,1.34,1.34,0,0,1-.42.28,1.61,1.61,0,0,1-.54.09,1.57,1.57,0,0,1-.54-.09,1.09,1.09,0,0,1-.42-.27,1.28,1.28,0,0,1-.28-.43,1.7,1.7,0,0,1-.1-.57V43.76h.48v2.15a1.11,1.11,0,0,0,.07.43.73.73,0,0,0,.19.28.61.61,0,0,0,.28.16.86.86,0,0,0,.32,0,.91.91,0,0,0,.33,0,.66.66,0,0,0,.28-.16.84.84,0,0,0,.2-.28,1.11,1.11,0,0,0,.07-.43V43.76h.47v2.16A1.47,1.47,0,0,1,120.67,46.48Z"/>
      <path fill={color} d="M123.63,47.23,122.86,46a.44.44,0,0,1-.06-.1.35.35,0,0,0-.06-.09s0,0,0-.05l0,.05-.06.09a.52.52,0,0,1-.07.11l-.76,1.18h-.54l1.18-1.83-1-1.64H122l.66,1,.11.18a1,1,0,0,1,.11-.19l.66-1h.54l-1,1.62,1.2,1.85Z"/>
      <path fill={color} d="M124.69,47.23V43.76h1.92v.45h-1.45v1.1h1.42v.45h-1.42v1h1.54v.45Z"/>
      <path fill={color} d="M130.19,47.23V44.54l0,.09-1.08,1.46h-.19l-1.08-1.43a.25.25,0,0,1,0-.07.62.62,0,0,1,0,.13v2.51h-.47V43.76h.4l1.29,1.7,1.26-1.7h.42v3.47Z"/>
      <path
      fill={color}
      d="M133.39,46.62a.8.8,0,0,1-.19.3.91.91,0,0,1-.33.22,1.14,1.14,0,0,1-.48.09h-1V43.76h.91a1.09,1.09,0,0,1,.4.07A.87.87,0,0,1,133,44a.83.83,0,0,1,.21.28,1.09,1.09,0,0,1,0,.86,1.05,1.05,0,0,1-.28.31.75.75,0,0,1,.2.1,1.07,1.07,0,0,1,.19.18.86.86,0,0,1,.14.25.94.94,0,0,1,0,.3A1.05,1.05,0,0,1,133.39,46.62Zm-.76-2.28a.7.7,0,0,0-.44-.13h-.36V45.3h.32a.91.91,0,0,0,.31,0,.59.59,0,0,0,.19-.14.5.5,0,0,0,.1-.19.82.82,0,0,0,0-.22A.45.45,0,0,0,132.63,44.34Zm.17,1.54a.66.66,0,0,0-.42-.13h-.55v1h.51a.65.65,0,0,0,.47-.15.49.49,0,0,0,.15-.38A.46.46,0,0,0,132.8,45.88Z"/>
      <path
      fill={color}
      d="M137.23,46.2a1.77,1.77,0,0,1-.94.95,1.72,1.72,0,0,1-.68.13,1.8,1.8,0,0,1-.69-.13,2,2,0,0,1-.56-.37,1.7,1.7,0,0,1-.37-.57,1.9,1.9,0,0,1-.14-.73,1.82,1.82,0,0,1,.13-.7,1.7,1.7,0,0,1,.37-.57,1.62,1.62,0,0,1,.55-.37,1.74,1.74,0,0,1,.69-.14,1.9,1.9,0,0,1,.73.14,1.79,1.79,0,0,1,.56.38,1.85,1.85,0,0,1,.36.57,2,2,0,0,1,.13.7A1.83,1.83,0,0,1,137.23,46.2ZM136.78,45a1.29,1.29,0,0,0-.26-.42,1.33,1.33,0,0,0-.41-.27,1.24,1.24,0,0,0-.52-.1,1.25,1.25,0,0,0-.51.1,1.36,1.36,0,0,0-.39.27,1.29,1.29,0,0,0-.26.42,1.46,1.46,0,0,0-.09.52,1.33,1.33,0,0,0,.1.54,1.54,1.54,0,0,0,.28.42,1.42,1.42,0,0,0,.41.28,1.12,1.12,0,0,0,.48.1,1.37,1.37,0,0,0,.49-.09,1.56,1.56,0,0,0,.4-.27,1.32,1.32,0,0,0,.27-.42,1.48,1.48,0,0,0,.11-.57A1.22,1.22,0,0,0,136.78,45Z"/>
      <path
      fill={color}
      d="M140.49,46.48a1.3,1.3,0,0,1-.29.43,1.24,1.24,0,0,1-.42.28,1.51,1.51,0,0,1-.53.09,1.63,1.63,0,0,1-.55-.09,1.17,1.17,0,0,1-.42-.27,1.12,1.12,0,0,1-.27-.43,1.48,1.48,0,0,1-.1-.57V43.76h.47v2.15a1.11,1.11,0,0,0,.07.43.73.73,0,0,0,.19.28.61.61,0,0,0,.28.16.88.88,0,0,0,.33,0,.86.86,0,0,0,.32,0,.61.61,0,0,0,.28-.16.84.84,0,0,0,.2-.28,1.1,1.1,0,0,0,.08-.43V43.76h.46v2.16A1.47,1.47,0,0,1,140.49,46.48Z"/>
      <path
      fill={color}
      d="M143.13,47.23l-1.39-1.62v1.62h-.47V43.76h1.11a1.23,1.23,0,0,1,.44.08,1.12,1.12,0,0,1,.33.22.91.91,0,0,1,.22.33,1,1,0,0,1,.07.39.85.85,0,0,1-.08.39.94.94,0,0,1-.53.53.89.89,0,0,1-.36.07l1.26,1.46Zm-.22-2.65a.36.36,0,0,0-.12-.18.58.58,0,0,0-.2-.13.65.65,0,0,0-.29-.06h-.56V45.4h.55a.66.66,0,0,0,.49-.18.6.6,0,0,0,.17-.45A.43.43,0,0,0,142.91,44.58Z"/>
      <path
      fill={color}
      d="M147.18,46.32a1.54,1.54,0,0,1-.35.51,1.41,1.41,0,0,1-.5.33,1.52,1.52,0,0,1-.59.12,1.77,1.77,0,0,1-.64-.12,1.88,1.88,0,0,1-.56-.37,1.76,1.76,0,0,1-.39-.56,1.78,1.78,0,0,1-.15-.74,1.76,1.76,0,0,1,.14-.73,1.57,1.57,0,0,1,.37-.56,1.76,1.76,0,0,1,.56-.37,1.9,1.9,0,0,1,.67-.13,1.93,1.93,0,0,1,.72.13,2.1,2.1,0,0,1,.55.34l-.29.36a1.5,1.5,0,0,0-.46-.28,1.69,1.69,0,0,0-.53-.08,1.2,1.2,0,0,0-.88.36,1.51,1.51,0,0,0-.27.42,1.63,1.63,0,0,0-.09.55,1.39,1.39,0,0,0,.09.5,1.34,1.34,0,0,0,.28.42,1.28,1.28,0,0,0,.4.29,1.14,1.14,0,0,0,.49.11,1.34,1.34,0,0,0,.35,0,.87.87,0,0,0,.29-.14,1.21,1.21,0,0,0,.23-.22,1.11,1.11,0,0,0,.14-.29h-1v-.45h1.58A1.75,1.75,0,0,1,147.18,46.32Z"/>
      <path
      fill={color}
      d="M151.58,47.1a1.73,1.73,0,0,1-.81.18,1.62,1.62,0,0,1-.61-.12,1.76,1.76,0,0,1-.54-.36,1.59,1.59,0,0,1-.38-.56,1.79,1.79,0,0,1-.15-.75,1.82,1.82,0,0,1,.13-.7,1.65,1.65,0,0,1,.35-.57,1.6,1.6,0,0,1,.54-.38,1.88,1.88,0,0,1,1.45,0,1.5,1.5,0,0,1,.5.4l-.35.31a1.28,1.28,0,0,0-.4-.29,1.19,1.19,0,0,0-.51-.1,1.27,1.27,0,0,0-.47.09,1.36,1.36,0,0,0-.39.27,1.15,1.15,0,0,0-.26.41,1.37,1.37,0,0,0-.1.54,1.51,1.51,0,0,0,.11.59,1.49,1.49,0,0,0,.29.42,1.36,1.36,0,0,0,.39.25,1.24,1.24,0,0,0,.41.08,1.43,1.43,0,0,0,.52-.11,1.3,1.3,0,0,0,.49-.37l.34.29A1.5,1.5,0,0,1,151.58,47.1Z"/>
      <path fill={color} d="M152.66,47.23V43.76h1.92v.45h-1.45v1.1h1.42v.45h-1.42v1h1.54v.45Z"/>
      <path fill={color} d="M157.58,47.23l-1.8-2.51-.06-.1a.66.66,0,0,1,0,.11v2.5h-.47V43.76h.4l1.78,2.46a.64.64,0,0,0,.06.1.54.54,0,0,1,0-.11V43.76H158v3.47Z"/>
      <path fill={color} d="M159.66,44.21v3h-.47v-3h-.7v-.45h1.9v.45Z"/>
      <path
      fill={color}
      d="M162.78,47.23l-1.39-1.62v1.62h-.47V43.76H162a1.09,1.09,0,0,1,.77.3,1.06,1.06,0,0,1,.22.33,1,1,0,0,1,.07.39.85.85,0,0,1-.08.39,1,1,0,0,1-.21.32,1.11,1.11,0,0,1-.32.21.9.9,0,0,1-.37.07l1.27,1.46Zm-.22-2.65a.36.36,0,0,0-.12-.18.58.58,0,0,0-.2-.13.65.65,0,0,0-.29-.06h-.56V45.4h.55a.66.66,0,0,0,.49-.18.6.6,0,0,0,.17-.45A.43.43,0,0,0,162.56,44.58Z"/>
      <path fill={color} d="M163.79,47.23V43.76h1.91v.45h-1.44v1.1h1.41v.45h-1.41v1h1.53v.45Z"/>
      <path fill={color} d="M168.27,44.21v1.1h1.15v.45h-1.15v1.47h-.47V43.76h1.69v.45Z"/>
      <path
      fill={color}
      d="M173.26,46.2a1.84,1.84,0,0,1-.38.57,1.8,1.8,0,0,1-.55.38,1.75,1.75,0,0,1-.68.13,1.73,1.73,0,0,1-.69-.13,1.82,1.82,0,0,1-.56-.37,1.73,1.73,0,0,1-.38-.57,1.9,1.9,0,0,1-.14-.73,2,2,0,0,1,.13-.7,1.7,1.7,0,0,1,.37-.57,1.62,1.62,0,0,1,.55-.37,1.74,1.74,0,0,1,.69-.14,1.9,1.9,0,0,1,.73.14,1.68,1.68,0,0,1,.56.38,1.85,1.85,0,0,1,.36.57,1.82,1.82,0,0,1,.13.7A1.83,1.83,0,0,1,173.26,46.2ZM172.82,45a1.51,1.51,0,0,0-.27-.42,1.41,1.41,0,0,0-.4-.27,1.3,1.3,0,0,0-.52-.1,1.19,1.19,0,0,0-.51.1,1.16,1.16,0,0,0-.66.69,1.46,1.46,0,0,0-.09.52,1.33,1.33,0,0,0,.11.54,1.19,1.19,0,0,0,.28.42,1.26,1.26,0,0,0,.4.28,1.16,1.16,0,0,0,.49.1,1.32,1.32,0,0,0,.48-.09,1.56,1.56,0,0,0,.4-.27,1.54,1.54,0,0,0,.28-.42,1.7,1.7,0,0,0,.1-.57A1.4,1.4,0,0,0,172.82,45Z"/>
      <path
      fill={color}
      d="M175.82,47.23l-1.39-1.62v1.62H174V43.76h1.11a1.23,1.23,0,0,1,.44.08,1.12,1.12,0,0,1,.33.22.91.91,0,0,1,.22.33,1,1,0,0,1,.07.39.85.85,0,0,1-.08.39.94.94,0,0,1-.53.53.89.89,0,0,1-.36.07l1.26,1.46Zm-.22-2.65a.36.36,0,0,0-.12-.18.58.58,0,0,0-.2-.13.65.65,0,0,0-.29-.06h-.56V45.4H175a.64.64,0,0,0,.49-.18.6.6,0,0,0,.17-.45A.43.43,0,0,0,175.6,44.58Z"/>
      <path
      fill={color}
      d="M118.21,53.67a1.72,1.72,0,0,1-.8.19,1.68,1.68,0,0,1-.62-.13,1.6,1.6,0,0,1-.54-.36,1.73,1.73,0,0,1-.38-.56,1.78,1.78,0,0,1-.15-.74,1.89,1.89,0,0,1,.48-1.28,1.75,1.75,0,0,1,.54-.38,1.73,1.73,0,0,1,.69-.13,1.84,1.84,0,0,1,.76.14,1.41,1.41,0,0,1,.5.4l-.34.31a1.46,1.46,0,0,0-.41-.29,1.35,1.35,0,0,0-.5-.1,1.12,1.12,0,0,0-.48.1,1.16,1.16,0,0,0-.39.26,1.19,1.19,0,0,0-.26.42,1.53,1.53,0,0,0-.1.54,1.31,1.31,0,0,0,.12.58,1.17,1.17,0,0,0,.29.42,1.14,1.14,0,0,0,.39.25,1.19,1.19,0,0,0,.41.08,1.42,1.42,0,0,0,.51-.11,1.2,1.2,0,0,0,.49-.37l.34.3A1.49,1.49,0,0,1,118.21,53.67Z"/>
      <path
      fill={color}
      d="M122.4,52.77a1.9,1.9,0,0,1-.38.57,1.68,1.68,0,0,1-.56.38,1.72,1.72,0,0,1-.68.14,1.81,1.81,0,0,1-.69-.14,1.76,1.76,0,0,1-.56-.37,1.85,1.85,0,0,1-.51-1.29,1.83,1.83,0,0,1,.13-.71,1.92,1.92,0,0,1,.36-.56,1.68,1.68,0,0,1,.56-.38,1.73,1.73,0,0,1,.69-.13,1.85,1.85,0,0,1,.72.13,1.73,1.73,0,0,1,.56.38,1.93,1.93,0,0,1,.37.57,2.08,2.08,0,0,1,.12.71A1.78,1.78,0,0,1,122.4,52.77ZM122,51.53a1.15,1.15,0,0,0-.26-.41,1.2,1.2,0,0,0-.41-.28,1.38,1.38,0,0,0-1,0,1.21,1.21,0,0,0-.39.28,1.15,1.15,0,0,0-.26.41,1.51,1.51,0,0,0-.09.53,1.32,1.32,0,0,0,.1.53,1.24,1.24,0,0,0,.28.42,1.15,1.15,0,0,0,.41.28,1.12,1.12,0,0,0,.48.1,1.28,1.28,0,0,0,.48-.09,1.09,1.09,0,0,0,.4-.27,1.34,1.34,0,0,0,.28-.42,1.43,1.43,0,0,0,.11-.56A1.46,1.46,0,0,0,122,51.53Z"/>
      <path fill={color} d="M125.42,53.8l-1.8-2.51-.06-.1a.68.68,0,0,1,0,.12V53.8h-.47V50.33h.4l1.77,2.47.07.1a.62.62,0,0,1,0-.12V50.33h.47V53.8Z"/>
      <path fill={color} d="M127.5,50.79v3H127v-3h-.7v-.46h1.9v.46Z"/>
      <path fill={color} d="M128.76,53.8V50.33h1.91v.46h-1.44v1.09h1.41v.45h-1.41v1h1.53v.45Z"/>
      <path fill={color} d="M134.25,53.8V51.25a.66.66,0,0,1,0-.13l0,.08-1.07,1.47H133l-1.09-1.43,0-.08c0,.05,0,.1,0,.13V53.8h-.47V50.33h.4l1.29,1.7,1.25-1.7h.42V53.8Z"/>
      <path
      fill={color}
      d="M137.49,51.8a1,1,0,0,1-.55.55.93.93,0,0,1-.4.09h-.64V53.8h-.47V50.33h1a1.16,1.16,0,0,1,.47.09,1.09,1.09,0,0,1,.36.22,1.11,1.11,0,0,1,.22.34,1.16,1.16,0,0,1,.08.42A.93.93,0,0,1,137.49,51.8Zm-.59-.86a.81.81,0,0,0-.52-.15h-.48V52h.51a.78.78,0,0,0,.49-.16.56.56,0,0,0,.19-.46A.51.51,0,0,0,136.9,50.94Z"/>
      <path
      fill={color}
      d="M141.34,52.77a1.73,1.73,0,0,1-.38.57,1.64,1.64,0,0,1-.55.38,1.76,1.76,0,0,1-.68.14,1.74,1.74,0,0,1-.69-.14,1.66,1.66,0,0,1-.56-.37,1.73,1.73,0,0,1-.38-.57,1.89,1.89,0,0,1-.14-.72,2,2,0,0,1,.13-.71,1.76,1.76,0,0,1,.37-.56,1.64,1.64,0,0,1,.55-.38,1.73,1.73,0,0,1,.69-.13,1.9,1.9,0,0,1,.73.13,1.85,1.85,0,0,1,.56.38,2.12,2.12,0,0,1,.36.57,1.87,1.87,0,0,1,.13.71A1.78,1.78,0,0,1,141.34,52.77Zm-.44-1.24a1.17,1.17,0,0,0-.27-.41,1.26,1.26,0,0,0-.4-.28,1.5,1.5,0,0,0-.52-.1,1.36,1.36,0,0,0-.51.1,1.24,1.24,0,0,0-.66.69,1.51,1.51,0,0,0-.09.53,1.32,1.32,0,0,0,.11.53,1.34,1.34,0,0,0,.68.7,1.19,1.19,0,0,0,.49.1,1.32,1.32,0,0,0,.48-.09,1.18,1.18,0,0,0,.4-.27,1.34,1.34,0,0,0,.28-.42,1.43,1.43,0,0,0,.1-.56A1.46,1.46,0,0,0,140.9,51.53Z"/>
      <path
      fill={color}
      d="M143.9,53.8l-1.39-1.62V53.8H142V50.33h1.11a1.23,1.23,0,0,1,.44.08,1,1,0,0,1,.33.23.87.87,0,0,1,.22.32,1,1,0,0,1,.07.39.85.85,0,0,1-.08.39,1,1,0,0,1-.21.32,1.06,1.06,0,0,1-.31.21,1,1,0,0,1-.37.08l1.26,1.45Zm-.21-2.65a.68.68,0,0,0-.13-.18.77.77,0,0,0-.2-.13.65.65,0,0,0-.29-.05h-.56V52h.55a.64.64,0,0,0,.49-.18.6.6,0,0,0,.18-.45A.72.72,0,0,0,143.69,51.15Z"/>
      <path fill={color} d="M147.14,53.8l-.41-1.12h-1.1l-.41,1.12h-.5L146,50.33h.3l1.32,3.47Zm-.93-2.54a.05.05,0,1,0-.06,0l-.35,1h.76Z"/>
      <path
      fill={color}
      d="M150,53.8l-1.39-1.62V53.8h-.47V50.33h1.11a1.23,1.23,0,0,1,.44.08,1,1,0,0,1,.33.23.87.87,0,0,1,.22.32,1,1,0,0,1,.07.39.85.85,0,0,1-.08.39,1,1,0,0,1-.21.32,1.06,1.06,0,0,1-.31.21,1,1,0,0,1-.37.08l1.26,1.45Zm-.21-2.65a.68.68,0,0,0-.13-.18.77.77,0,0,0-.2-.13.65.65,0,0,0-.29-.05h-.56V52h.55a.64.64,0,0,0,.49-.18.6.6,0,0,0,.18-.45A.72.72,0,0,0,149.78,51.15Z"/>
      <path fill={color} d="M152.16,52.19V53.8h-.47V52.19l-1.18-1.86h.55l.82,1.3.06.1a.43.43,0,0,1,.05-.1l.83-1.3h.53Z"/>
      <path fill={color} d="M157,53.8l-.41-1.12h-1.1l-.41,1.12h-.5l1.32-3.47h.3l1.32,3.47Zm-.94-2.54a.84.84,0,0,0,0-.1.41.41,0,0,1,0,.1l-.35,1h.76Z"/>
      <path fill={color} d="M160.33,53.8l-1.8-2.51-.06-.1a.68.68,0,0,1,0,.12V53.8H158V50.33h.4l1.78,2.47.06.1a.62.62,0,0,1,0-.12V50.33h.47V53.8Z"/>
      <path
      fill={color}
      d="M164,52.74a1.54,1.54,0,0,1-.36.55,1.79,1.79,0,0,1-.59.37,2.22,2.22,0,0,1-.79.14h-.84V50.33h.88a2.13,2.13,0,0,1,.78.14,1.52,1.52,0,0,1,.57.37,1.54,1.54,0,0,1,.36.55,1.85,1.85,0,0,1,.12.67A1.75,1.75,0,0,1,164,52.74Zm-.45-1.18a1.3,1.3,0,0,0-.28-.41,1.24,1.24,0,0,0-.42-.28,1.43,1.43,0,0,0-.56-.11h-.4v2.61h.31a1.8,1.8,0,0,0,.65-.11,1.48,1.48,0,0,0,.45-.3,1,1,0,0,0,.26-.42,1.34,1.34,0,0,0,.08-.48A1.39,1.39,0,0,0,163.54,51.56Z"/>
      <path
      fill={color}
      d="M168.68,52.74a1.72,1.72,0,0,1-.37.55,1.79,1.79,0,0,1-.59.37,2.22,2.22,0,0,1-.79.14h-.84V50.33H167a2.13,2.13,0,0,1,.78.14,1.55,1.55,0,0,1,.93.92,1.85,1.85,0,0,1,.12.67A2,2,0,0,1,168.68,52.74Zm-.46-1.18a1.3,1.3,0,0,0-.28-.41,1.15,1.15,0,0,0-.42-.28,1.43,1.43,0,0,0-.56-.11h-.4v2.61h.31a1.8,1.8,0,0,0,.65-.11A1.27,1.27,0,0,0,168,53a1,1,0,0,0,.26-.42,1.34,1.34,0,0,0,.08-.48A1.39,1.39,0,0,0,168.22,51.56Z"/>
      <path fill={color} d="M169.37,53.8V50.33h.47V53.8Z"/>
      <path
      fill={color}
      d="M173.58,52.89a1.54,1.54,0,0,1-.35.51,1.43,1.43,0,0,1-.5.34,1.48,1.48,0,0,1-.59.12,1.78,1.78,0,0,1-.64-.13,1.87,1.87,0,0,1-.55-.36,1.78,1.78,0,0,1-.4-.57,1.73,1.73,0,0,1-.15-.73,2,2,0,0,1,.14-.74,1.68,1.68,0,0,1,.38-.56,1.54,1.54,0,0,1,.55-.36,1.74,1.74,0,0,1,.67-.13,1.94,1.94,0,0,1,.72.12,2.14,2.14,0,0,1,.55.35l-.29.35a1.46,1.46,0,0,0-.46-.27,1.44,1.44,0,0,0-.53-.09,1.12,1.12,0,0,0-.48.1,1.09,1.09,0,0,0-.4.27,1.11,1.11,0,0,0-.26.42,1.33,1.33,0,0,0-.1.54,1.36,1.36,0,0,0,.37.92,1.24,1.24,0,0,0,.4.29,1,1,0,0,0,.49.11,1.34,1.34,0,0,0,.35,0,1,1,0,0,0,.3-.14A1.15,1.15,0,0,0,173,53a.78.78,0,0,0,.14-.29h-1v-.45h1.58A1.54,1.54,0,0,1,173.58,52.89Z"/>
      <path fill={color} d="M174.23,53.8V50.33h.47V53.8Z"/>
      <path fill={color} d="M176.4,50.79v3h-.47v-3h-.7v-.46h1.9v.46Z"/>
      <path fill={color} d="M179.47,53.8l-.42-1.12H178l-.42,1.12h-.49l1.31-3.47h.3L180,53.8Zm-.94-2.54a.41.41,0,0,1,0-.1.56.56,0,0,1,0,.1l-.36,1h.77Z"/>
      <path fill={color} d="M180.46,53.8V50.33h.47v3h1.3v.45Z"/>
      <path fill={color} d="M186.23,53.8V52.31h-1.64V53.8h-.47V50.33h.47v1.53h1.64V50.33h.47V53.8Z"/>
      <path fill={color} d="M187.41,53.8V50.33h.47V53.8Z"/>
      <path
      fill={color}
      d="M190.42,53.3a1,1,0,0,1-.23.3.88.88,0,0,1-.34.19,1.05,1.05,0,0,1-.4.07,1.82,1.82,0,0,1-.52-.08,1.5,1.5,0,0,1-.49-.27l.27-.37a.91.91,0,0,0,.16.11l.18.08.19.05.18,0a.71.71,0,0,0,.43-.12.42.42,0,0,0,.16-.37.45.45,0,0,0,0-.2,1.83,1.83,0,0,0-.13-.16.83.83,0,0,0-.18-.13l-.22-.12-.36-.19a1.81,1.81,0,0,1-.32-.22.84.84,0,0,1-.22-.3.93.93,0,0,1-.09-.42.81.81,0,0,1,.07-.35.72.72,0,0,1,.2-.29.86.86,0,0,1,.31-.19,1.27,1.27,0,0,1,.41-.06,1.61,1.61,0,0,1,.49.07,1.59,1.59,0,0,1,.43.22l-.25.39a1.38,1.38,0,0,0-.31-.17,1.27,1.27,0,0,0-.37,0,.62.62,0,0,0-.34.1.34.34,0,0,0-.15.3.43.43,0,0,0,.06.24.55.55,0,0,0,.16.18l.24.15.3.15L190,52a1.09,1.09,0,0,1,.26.21.92.92,0,0,1,.19.28.84.84,0,0,1,.07.37A1.08,1.08,0,0,1,190.42,53.3Z"/>
      <path fill={color} d="M192,50.79v3h-.47v-3h-.7v-.46h1.9v.46Z"/>
      <path
      fill={color}
      d="M196.22,52.77a1.73,1.73,0,0,1-.38.57,1.75,1.75,0,0,1-.55.38,1.77,1.77,0,0,1-.69.14,1.72,1.72,0,0,1-.68-.14,1.63,1.63,0,0,1-.94-.94,1.89,1.89,0,0,1-.14-.72,2,2,0,0,1,.13-.71,1.76,1.76,0,0,1,.37-.56,1.64,1.64,0,0,1,.55-.38,1.73,1.73,0,0,1,.69-.13,1.9,1.9,0,0,1,.73.13,2,2,0,0,1,.56.38,2.12,2.12,0,0,1,.36.57,2.08,2.08,0,0,1,.13.71A1.78,1.78,0,0,1,196.22,52.77Zm-.45-1.24a1.15,1.15,0,0,0-.26-.41,1.2,1.2,0,0,0-.41-.28,1.42,1.42,0,0,0-.52-.1,1.35,1.35,0,0,0-.5.1,1.24,1.24,0,0,0-.66.69,1.51,1.51,0,0,0-.09.53,1.32,1.32,0,0,0,.1.53,1.4,1.4,0,0,0,.28.42,1.25,1.25,0,0,0,.41.28,1.15,1.15,0,0,0,.48.1,1.37,1.37,0,0,0,.49-.09,1.18,1.18,0,0,0,.4-.27,1.32,1.32,0,0,0,.27-.42,1.43,1.43,0,0,0,.11-.56A1.27,1.27,0,0,0,195.77,51.53Z"/>
      <path
      fill={color}
      d="M198.78,53.8l-1.39-1.62V53.8h-.47V50.33H198a1.18,1.18,0,0,1,.43.08A1.07,1.07,0,0,1,199,51a1,1,0,0,1,.07.39.85.85,0,0,1-.08.39.94.94,0,0,1-.53.53.91.91,0,0,1-.37.08l1.27,1.45Zm-.22-2.65a.47.47,0,0,0-.12-.18.77.77,0,0,0-.2-.13.65.65,0,0,0-.29-.05h-.56V52h.55a.66.66,0,0,0,.49-.18.59.59,0,0,0,.17-.45A.46.46,0,0,0,198.56,51.15Z"/>
      <path fill={color} d="M200.94,52.19V53.8h-.47V52.19l-1.18-1.86h.55l.83,1.3.05.1a.43.43,0,0,1,.05-.1l.83-1.3h.54Z"/><rect fill={color} x="176.4" y="18.13" width="5.47" height="5.47"/><rect fill={color} x="185.28" y="26.65" width="5.47" height="5.47"/><rect fill={color} x="179.96" y="10.14" width="5.47" height="5.47"/><rect fill={color} x="153.09" y="9.57" width="5.47" height="5.47"/><rect fill={color} x="166.9" y="7.27" width="5.47" height="5.47"/><rect fill={color} x="176.59" y="30.35" width="5.47" height="5.47"/><rect fill={color} x="139.82" y="3.73" width="5.47" height="5.47"/>
      <path fill={color} d="M149.52,26a5.61,5.61,0,0,0-1.84-1.21,6.26,6.26,0,0,0-2.43-.45h-1.71v11.2h1.33a7.31,7.31,0,0,0,2.83-.49,5.76,5.76,0,0,0,1.94-1.28,5,5,0,0,0,1.12-1.8,6.11,6.11,0,0,0,.36-2.05,5.53,5.53,0,0,0-.42-2.15A5.44,5.44,0,0,0,149.52,26Z"/>
      <path
        fill={color}
        d="M115.82,18.73V40.1h54.85V18.73Zm14.08,18.1a7.46,7.46,0,0,1-3.5.8,6.87,6.87,0,0,1-2.66-.54,7.36,7.36,0,0,1-2.33-1.53,7.45,7.45,0,0,1-1.66-2.41,7.84,7.84,0,0,1-.63-3.2,8.09,8.09,0,0,1,.55-3,7.5,7.5,0,0,1,1.52-2.45,7,7,0,0,1,2.33-1.62,7.26,7.26,0,0,1,3-.6,7.52,7.52,0,0,1,3.3.64A6.54,6.54,0,0,1,132,24.6l-1.5,1.34a5.6,5.6,0,0,0-1.76-1.23,5.32,5.32,0,0,0-2.19-.44,5.25,5.25,0,0,0-2,.41,5.15,5.15,0,0,0-1.69,1.14,5.39,5.39,0,0,0-1.14,1.77,6.65,6.65,0,0,0,.07,4.83A5.47,5.47,0,0,0,123,34.2a5.09,5.09,0,0,0,1.69,1.07,4.91,4.91,0,0,0,1.78.36,6,6,0,0,0,2.24-.47,5.44,5.44,0,0,0,2.12-1.59l1.47,1.27A6.6,6.6,0,0,1,129.9,36.83Zm9.46-6.23h-5.75l3.76-4.76a2.34,2.34,0,0,0,.47-.81,1.89,1.89,0,0,0,.1-.56,1.53,1.53,0,0,0-.17-.76,1.35,1.35,0,0,0-.41-.47,1.56,1.56,0,0,0-.52-.25,2.49,2.49,0,0,0-.5-.07,1.88,1.88,0,0,0-.74.14,1.34,1.34,0,0,0-.5.37,1.41,1.41,0,0,0-.29.54,2.25,2.25,0,0,0-.09.64h-1.14a3.09,3.09,0,0,1,.22-1.22A2.4,2.4,0,0,1,135.26,22a3.24,3.24,0,0,1,1.09-.18,3.5,3.5,0,0,1,1,.15,2.62,2.62,0,0,1,.89.47,2.56,2.56,0,0,1,.64.83,2.81,2.81,0,0,1,.24,1.23,3,3,0,0,1-.17.94,3.09,3.09,0,0,1-.58,1l-2.48,3h3.45Zm13.32,2.22a6.6,6.6,0,0,1-1.59,2.37,7.63,7.63,0,0,1-2.56,1.6,9.36,9.36,0,0,1-3.42.59h-3.6V22.52h3.78a9.14,9.14,0,0,1,3.38.58,7.24,7.24,0,0,1,2.5,1.59A6.9,6.9,0,0,1,152.71,27a7.78,7.78,0,0,1,.52,2.87A7.65,7.65,0,0,1,152.68,32.82Zm14.19,4.56h-2V31h-7.13v6.39h-2V22.52h2v6.53h7.13V22.52h2Z"/>
    </svg>
    </a>
    </div>
  )
}

export default C2dhUnilu
